import React from 'react';
import { getImageForClass, getPkLevelColor, getPkLevelString } from '../../utils';
import './tabla.css';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Grid } from '@mui/material';
function TablaPK({ jugadores }) {
  const maxOrden = Math.max(...jugadores.map(jugador => jugador.Orden), 0);
  const nuevoOrden = maxOrden + 1;

  return (
    <TableContainer component={Paper}>
      <Table className='table'>
        <TableHead>
          <TableRow>
            <TableCell style={{ color: 'white', textAlign: 'center', textTransform: 'uppercase' }}>#</TableCell>
            <TableCell style={{ color: 'white', textAlign: 'center', textTransform: 'uppercase' }}>Personaje</TableCell>
            <TableCell style={{ color: 'white', textAlign: 'center', textTransform: 'uppercase' }}>Estado</TableCell>
            <TableCell style={{ color: 'white', textAlign: 'center', textTransform: 'uppercase' }}>Clase</TableCell>
            <TableCell style={{ color: 'white', textAlign: 'center', textTransform: 'uppercase' }}>Puntos PK</TableCell>
            <TableCell style={{ color: 'white', textAlign: 'center', textTransform: 'uppercase' }}>Muertes sin perdonar</TableCell>
            {/* <TableCell style={{ color: 'white', textAlign: 'center', textTransform: 'uppercase' }}>Acumuladas</TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {jugadores.map((jugador) => (
            <TableRow key={jugador.Orden}>
              <TableCell style={{ color: '#cdcdcd' }}>{jugador.Orden}</TableCell>


              <TableCell style={{ color: '#cdcdcd' }}>
                <div className={jugador.ConnectStat === 1 ? 'connected' : 'disconnected'}>
                  <div className="player-container">
                    <Grid style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', width: '100%' }}>

                      {
                        jugador.ConnectStat === 1 &&
                        <span id="pulsatingDot" style={{ marginRight: '5px' }} />

                      }


                      <span style={{ color: jugador.ConnectStat === 1 ? 'green' : '#cdcdcd', display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                        {jugador.Name}

                      </span>
                      {jugador.IsMaster === 1 && <img src="assets/img/star.gif" alt="Guild Master" title="Es Guild Master" style={{ width: '12px', height: '12px', border: 'none', marginLeft: '4px' }} />}

                    </Grid>
                  </div>
                </div>
              </TableCell>
              <TableCell>
                <span style={{ backgroundColor: getPkLevelColor(jugador.PkLevel), color: 'black', padding: '2px 5px', borderRadius: '3px', fontSize: '10px' }}>
                  {getPkLevelString(jugador.PkLevel)}
                </span>
              </TableCell>
              <TableCell style={{ color: '#cdcdcd' }}>
                <img src={`assets/img/${getImageForClass(jugador.Class)}`} width="30" alt="Conectado" title={jugador.Class} style={{ marginRight: '8px' }} />
              </TableCell>
              <TableCell style={{ color: '#cdcdcd' }}>{jugador.PkPoints_EXT}</TableCell>
              <TableCell style={{ color: '#cdcdcd' }}>{jugador.PkCount}</TableCell>
              {/* <TableCell style={{ color: '#cdcdcd' }}>{jugador.Kills}</TableCell> */}
            </TableRow>
          ))}
          <TableRow>
            <TableCell style={{ color: '#cdcdcd' }}>{nuevoOrden}</TableCell>
            <TableCell colSpan={5} style={{ textAlign: 'center', color: '#cdcdcd' }}>¡¡PODRÍAS SER VOS!!</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default TablaPK;
