import React, { useContext, useEffect, useRef, useState } from 'react'
import Layout from '../layout/Layout'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Drawer, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import { TbHelpHexagonFilled } from 'react-icons/tb'
import { FaCalendarAlt, FaEdit, FaTrash } from 'react-icons/fa'
import AuthContext from '../../AuthContext'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import HtmlEditor, { Toolbar, MediaResizing, ImageUpload, FileUploaderOptions, Item } from 'devextreme-react/html-editor';
import 'devextreme/dist/css/dx.light.css'; // Import DevExtreme styles
import foto1 from './foto3.webp'
import './ayuda.css'
function Ayuda(props) {

    const quillRef = useRef(null); // Crear un ref para ReactQuill
    const { user } = useContext(AuthContext);
    const [habilitarCrear, setHabilitarCrear] = useState();
    const [categorias, setCategorias] = useState();
    const [guias, setGuias] = useState();
    // Función para obtener la lista de noticias
    const fetchCatGuias = async () => {
        try {
            const response = await axios.get('https://backmu.vercel.app/guia');
            console.log('response', response)

            setCategorias(response.data);
        } catch (error) {
            console.error('Error al obtener noticias:', error);
        }
    };
    const fetchGuias = async () => {
        try {
            const response = await axios.get('https://backmu.vercel.app/ayudaGuia');
            setGuias(response.data);
        } catch (error) {
            console.error('Error al obtener noticias:', error);
        }
    };
    // Efecto para cargar la lista de noticias cuando el componente se monta
    useEffect(() => {
        fetchCatGuias();
        fetchGuias();
    }, []);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [isDrawerGuiaOpen, setIsDrawerGuiaOpen] = useState(false)
    const [formData, setFormData] = useState({
        titulo: '',
        descripcion: '',
        imagen: ''
    });




    const [formGuiaData, setFormGuiaData] = useState({
        titulo: '',
        cuerpo: '',
        categoriaId: '',
        imagen: ''
    });

    const handleChangeGuia = (e) => {
        const { name, value } = e.target;
        setFormGuiaData({
            ...formGuiaData,
            [name]: value
        });
    };
    const handleChangeGuia2 = (e) => {
        let content = e.value;

        // Eliminar etiquetas <p> alrededor de <img>
        content = content?.replace(/&lt;img/g, '<img')?.replace(/\/&gt;/g, '/>')?.replace(/&quot;/g, '"');


        setFormGuiaData({
            ...formGuiaData,
            cuerpo: content
        });
    };



    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleCreateGuia = async (e) => {
        try {
            e.preventDefault();

            const response = await axios.post('https://backmu.vercel.app/ayudaGuia', formGuiaData);
            // Deberías recibir el mensaje indicando que la categoria se creó exitosamente
            // Actualiza la lista de noticias después de crear una nueva
            fetchGuias();
            // Reinicia el formulario
            setFormGuiaData({ titulo: '', contenido: '', descripcion: '' });
            setIsDrawerGuiaOpen(false); // Cierra el Drawer después de enviar el formulario

        } catch (error) {
            console.error('Error al crear la guia:', error);
        }
    };

    const handleCreateTema = async (e) => {
        try {
            e.preventDefault();

            const response = await axios.post('https://backmu.vercel.app/guia', formData);

            // Actualiza la lista de noticias después de crear una nueva
            fetchCatGuias();
            // Reinicia el formulario
            setFormData({ titulo: '', descripcion: '' });
            setIsDrawerOpen(false); // Cierra el Drawer después de enviar el formulario

        } catch (error) {
            console.error('Error al crear tema:', error);
        }
    };


    // Configure image upload
    const uploadImage = async (file) => {
        const cloudName = 'dks7seyl8';
        const uploadPreset = 'ml_default';
        const uploadUrl = `https://api.cloudinary.com/v1_1/${cloudName}/image/upload`;

        const formData = new FormData();
        formData.append('file', file);
        formData.append('upload_preset', uploadPreset);

        try {
            const response = await fetch(uploadUrl, {
                method: 'POST',
                body: formData,
            });
            const data = await response.json();
            // Return the URL of the uploaded image
            return data.secure_url;
        } catch (error) {
            console.error('Image upload failed:', error);
            return '';
        }
    };

    const [categoryEdit, setCategoryEdit] = useState()
    const [openEdit, setOpenEdit] = useState(false)
    const [formEditData, setFormEditData] = useState({ titulo: '', descripcion: '', imagen: '' });

    const [guiasFiltradas, setGuiasFiltradas] = useState()
    const [catSeleccionada, setCatSeleccionada] = useState()
    //FILTRAR POR CATEGORIA //
    const filterbyCategory = async (cat) => {
        setCatSeleccionada(cat)
        const response = await axios.get(`https://backmu.vercel.app/guia/${cat?._id}`);

        setGuiasFiltradas(response.data?.guias)
    }

    //
    // EDITAR CATEGORIA 
    const handleEditClick = (category) => {

        setCategoryEdit(category);
        setFormEditData({ titulo: category?.titulo, descripcion: category?.descripcion })
        setOpenEdit(true);
    };


    const handleDrawerEditClose = () => {
        setOpenEdit(false);
        setCategoryEdit(null);
    };

    const handleSave = async () => {
        try {
            const response = await axios.put(`https://backmu.vercel.app/guia/${categoryEdit?._id}`, formEditData);
            if (response.status === 200) {
                console.log('Categoría actualizada');
                fetchCatGuias();
                setOpenEdit(false);
            } else {
                console.error('Error al actualizar la categoría');
            }
        } catch (error) {
            console.error('Error en la solicitud de actualización:', error);
        }
    };

    const handleInputChange = (e) => {

        setFormEditData((prevFormData) => ({
            ...prevFormData,
            [e.target.name]: e.target.value,
        }));
    };
    //

    // ELIMINAR CATEGORIA 
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedCategoryId, setSelectedCategoryId] = useState(null);

    const handleDeleteClick = (id) => {
        setSelectedCategoryId(id);
        setOpenDialog(true);
    };
    const handleDeleteConfirm = async () => {
        try {
            const response = await axios.delete(`https://backmu.vercel.app/guia/${selectedCategoryId}`);
            if (response.status === 200) {
                console.log('Categoría eliminada');
                setOpenDialog(false);
                // Actualiza la lista de noticias después de eliminar
                fetchCatGuias();
                fetchGuias();
            } else {
                console.error('Error al eliminar la categoría');
            }
        } catch (error) {
            console.error('Error en la solicitud de eliminación:', error);
        }
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
        setSelectedCategoryId(null);
    };
    ///

    const [guiaSelect, setGuiaSelect] = useState()
    const [openDeleteGuia, setOpenDeleteGuia] = useState(false)

    // ELIMINAR GUIA //
    const handleDeleteGuia = (guia) => {

        setGuiaSelect(guia);
        setOpenDeleteGuia(true);
    }
    const handleGuiaDialogClose = () => {
        setOpenDeleteGuia(false);
        setGuiaSelect(null);
    };
    const handleDeleteConfirmGuia = async () => {
        try {
            const response = await axios.delete(`https://backmu.vercel.app/ayudaGuia/${guiaSelect?._id}`);
            if (response.status === 200) {
                console.log('Guia eliminada');
                setOpenDeleteGuia(false);
                // Actualiza la lista de noticias después de eliminar
                fetchCatGuias();
                fetchGuias();
            } else {
                console.error('Error al eliminar la guia');
            }
        } catch (error) {
            console.error('Error en la solicitud de eliminación:', error);
        }
    };

    // 

    const [guiaEdit, setGuiaEdit] = useState()
    const [openEditGuia, setOpenEditGuia] = useState(false)
    const [formEditGuia, setFormEditGuia] = useState({ titulo: '', cuerpo: '', categoriaId: guiaEdit?._id, imagen: '' });

    // EDITAR GUIA //
    const handleEditGuia = (guia) => {
        setGuiaEdit(guia);
        setOpenEditGuia(true);
    };


    const handleDrawerEditGuiaClose = () => {
        setOpenEditGuia(false);
        setGuiaEdit(null);
    };

    const handleInputChangeGuia = (e) => {

        setFormEditGuia((prevFormData) => ({
            ...guiaEdit,
            cuerpo: e.value,
        }));
    };
    const handleInputChangeGuia2 = (e) => {

        setFormEditGuia((prevFormData) => (
            {
                ...guiaEdit,
                [e.target.name]: e.target.value,
            }));
    };

    console.log('formEditGuia', formEditGuia)
    const handleSaveGuia = async () => {
        try {
            const response = await axios.put(`https://backmu.vercel.app/ayudaGuia/${guiaEdit?._id}`, formEditGuia);
            if (response.status === 200) {
                console.log('Guia actualizada');
                fetchGuias();
                setOpenEditGuia(false);
            } else {
                console.error('Error al actualizar la categoría');
            }
        } catch (error) {
            console.error('Error en la solicitud de actualización:', error);
        }
    };

    //
    const navigate = useNavigate(); // Para React Router v6 en adelante
    const handleClick = (id) => {

        navigate(`/guia/${id}`); // Para React Router v6 en adelante
        // history.push(`/guia/${categoryTitle}`); // Para React Router v5 o anterior
    };
    function stripHtml(html) {
        const temporalDivElement = document.createElement("div");
        temporalDivElement.innerHTML = html;
        return temporalDivElement.textContent || temporalDivElement.innerText || "";
    }
    function excerpt(text, wordLimit = 25) {
        const plainText = stripHtml(text); // Eliminar HTML del texto
        const words = plainText.split(' '); // Dividir el texto en palabras

        // Si el número de palabras es menor o igual al límite, devolver el texto completo
        if (words.length <= wordLimit) {
            return plainText;
        }

        // Recortar el array de palabras al límite y unirlas de nuevo con espacios
        const truncatedText = words.slice(0, wordLimit).join(' ');

        // Añadir "..." al final del texto recortado
        return `${truncatedText}...`;
    }


    return (
        <Layout tyExpanded={props.tyExpanded} setExpanded={props.setExpanded} isMobile={props.isMobile}>
            <Grid style={{ display: 'flex', flexDirection: props?.isMobile && 'column', alignItems: 'flex-start', justifyContent: 'space-around', padding: 15 }}>


                <Grid style={{ display: 'flex', flexDirection: 'column', width: props?.isMobile ? '100%' : '25%', color: 'white', gap: ' 15px' }}>
                    {
                        (user?.username === 'drako' || user?.username === 'eldenguee') &&
                        <Grid style={{ display: 'flex', gap: '10px' }}>

                            <button style={{
                                borderRadius: "5px",
                                fontFamily:
                                    "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                textAlign: "center",
                                gap: "5px",
                                backgroundColor: "transparent",
                                border: "1px solid #cd100a",
                                color: "white",
                                padding: "10px",
                                cursor: "pointer",
                            }}
                                className="boton-comprar" onClick={() => setIsDrawerOpen(true)} >
                                Crear Categoria
                            </button>



                        </Grid>
                    }
                    <button style={{
                        borderRadius: "5px",
                        fontFamily:
                            "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                        gap: "5px",
                        backgroundColor: "transparent",
                        border: "1px solid #cd100a",
                        color: "white",
                        padding: "10px",
                        cursor: "pointer",
                    }}
                        className="button-navbar-register" onClick={() => {
                            setGuiasFiltradas([]);
                            setCatSeleccionada(null); // Cambia `null` por el valor deseado o el estado inicial
                        }} >
                        Todos
                    </button>
                    <Drawer anchor="right" open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
                        <Box sx={{ width: 300, padding: 2 }}>
                            <Typography variant="h6" gutterBottom>
                                Crear Categoría de guía
                            </Typography>
                            <form onSubmit={handleCreateTema}>
                                <TextField
                                    label="Título"
                                    name="titulo"
                                    value={formData.titulo}
                                    onChange={handleChange}
                                    fullWidth
                                    margin="normal"
                                />
                                <TextField
                                    label="Imagen Destacada"
                                    name="imagen"
                                    value={formData.imagen}
                                    onChange={handleChange}
                                    fullWidth
                                    margin="normal"
                                />
                                <TextField
                                    label="Descripción"
                                    name="descripcion"
                                    value={formData.descripcion}
                                    onChange={handleChange}
                                    fullWidth
                                    margin="normal"
                                />
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        sx={{ marginRight: 1 }}
                                    >
                                        Crear
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        onClick={() => setIsDrawerOpen(false)}
                                    >
                                        Cancelar
                                    </Button>
                                </Box>
                            </form>
                        </Box>
                    </Drawer>

                    {/* Drawer para Editar */}
                    <Drawer
                        anchor="right"
                        open={openEdit}
                        onClose={handleDrawerEditClose}
                    >
                        <Box sx={{ width: 300, padding: 2 }}>
                            <Typography variant="h6" gutterBottom>
                                Editar Categoría
                            </Typography>
                            <Divider sx={{ marginBottom: 2 }} />
                            <TextField
                                label="Título"
                                name="titulo"
                                value={formEditData?.titulo !== '' ? formEditData?.titulo : categoryEdit?.titulo}
                                onChange={handleInputChange}
                                fullWidth
                                margin="normal"
                            />
                            <TextField
                                label="Imagen Destacada"
                                name="imagen"
                                value={formEditData?.imagen !== '' ? formEditData?.imagen : categoryEdit?.imagen}
                                onChange={handleInputChange}
                                fullWidth
                                margin="normal"
                            />

                            <TextField
                                label="Descripción"
                                name="descripcion"
                                value={formEditData?.descripcion !== '' ? formEditData?.descripcion : categoryEdit?.descripcion}
                                onChange={handleInputChange}
                                fullWidth
                                multiline
                                rows={4}
                                margin="normal"
                            />

                            <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 3 }}>
                                <Button onClick={handleDrawerEditClose} variant="outlined" color="primary" sx={{ marginRight: 1 }}>
                                    Cancelar
                                </Button>
                                <Button onClick={handleSave} variant="contained" color="secondary">
                                    Guardar
                                </Button>
                            </Box>
                        </Box>
                    </Drawer>



                    <Grid style={{ display: 'flex', flexDirection: 'column', borderRadius: '5px' }}>

                        {
                            categorias?.map((cat) => {

                                return (

                                    <Box key={cat?._id} style={{ padding: 10, display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer', backgroundColor: catSeleccionada?._id === cat?._id && 'rgba(19,20,24,0.9)' }} onClick={() => filterbyCategory(cat)} className='caja-cat'>
                                        <Grid style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
                                            <Grid style={{ borderRadius: '50%', width: '100px', height: '100px', transition: 'transform 0.3s ease, filter 0.3s ease', overflow: 'hidden' }} >
                                                <img src={cat?.imagen ?? foto1} style={{ width: '100px', height: '100%', objectFit: 'cover', transition: 'transform 0.3s ease, filter 0.3s ease' }} className='img-efecto' />

                                            </Grid>
                                            <Box>
                                                <Typography variant="h4" style={{ color: '#ed3d3d', fontSize: '18px' }}>{cat?.titulo}</Typography>
                                                <Typography variant="body">{cat?.descripcion}</Typography>
                                            </Box>
                                        </Grid>

                                        {(user?.username === 'drako' || user?.username === 'eldenguee') && (

                                            <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px' }}>
                                                <IconButton aria-label="edit" onClick={() => handleEditClick(cat)}>
                                                    <FaEdit style={{ color: 'white', fontSize: '16px' }} />
                                                </IconButton>
                                                <IconButton aria-label="delete" onClick={() => handleDeleteClick(cat?._id)}>
                                                    <FaTrash style={{ color: 'white', fontSize: '16px' }} />
                                                </IconButton>
                                            </Box>
                                        )}
                                    </Box>
                                )
                            })
                        }

                    </Grid>
                </Grid>


                {/* Drawer de Confirmación */}
                <Drawer
                    anchor="right"
                    open={openDialog}
                    onClose={handleDialogClose}
                >
                    <Box sx={{ width: 300, padding: 2 }}>
                        <Typography variant="h6" gutterBottom>
                            Confirmar Eliminación
                        </Typography>
                        <Divider sx={{ marginBottom: 2 }} />
                        <Typography variant="body1" gutterBottom>
                            ¿Estás seguro de que quieres eliminar la categoría <strong>{selectedCategoryId?.titulo}</strong>?
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                            Esta acción no se puede deshacer. La categoría y toda la información relacionada se eliminarán permanentemente.
                        </Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 3 }}>
                            <Button onClick={handleDialogClose} variant="outlined" color="primary" sx={{ marginRight: 1 }}>
                                Cancelar
                            </Button>
                            <Button onClick={handleDeleteConfirm} variant="contained" color="secondary">
                                Eliminar
                            </Button>
                        </Box>
                    </Box>
                </Drawer>

                <Grid style={{ display: 'flex', flexDirection: 'column', width: props?.isMobile ? '100%' : '60%', color: 'white' }}>


                    {(user?.username === 'drako' || user?.username === 'eldenguee') &&
                        <Grid style={{ display: 'flex', gap: '10px' }}>
                            <button style={{
                                borderRadius: "5px",
                                fontFamily:
                                    "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                textAlign: "center",
                                gap: "5px",
                                backgroundColor: "transparent",
                                border: "1px solid #cd100a",
                                color: "white",
                                padding: "10px",
                                cursor: "pointer",
                            }}
                                className="boton-comprar" onClick={() => setIsDrawerGuiaOpen(true)} >
                                Crear Guía
                            </button>
                        </Grid>

                    }

                    <Drawer anchor="right" open={isDrawerGuiaOpen} onClose={() => setIsDrawerGuiaOpen(false)}>
                        <Box sx={{ width: 800, padding: 2 }}>
                            <Typography variant="h6" gutterBottom>
                                Crear Nueva Guía
                            </Typography>
                            <form onSubmit={handleCreateGuia}>
                                <TextField
                                    label="Título"
                                    name="titulo"
                                    value={formGuiaData?.titulo}
                                    onChange={handleChangeGuia}
                                    fullWidth
                                    margin="normal"
                                />
                                <TextField
                                    label="Imagen Destacada"
                                    name="imagen"
                                    value={formGuiaData?.imagen}
                                    onChange={handleChangeGuia}
                                    fullWidth
                                    margin="normal"
                                />
                                <Typography variant="subtitle1" gutterBottom>
                                    Contenido
                                </Typography>
                                <HtmlEditor
                                    name='cuerpo'
                                    value={formGuiaData.cuerpo}
                                    onValueChanged={handleChangeGuia2}
                                    height={600} // Optional: set height of the editor

                                >
                                    <Toolbar>
                                        <Item name="undo" />
                                        <Item name="redo" />
                                        <Item name="separator" />
                                        <Item name="bold" />
                                        <Item name="italic" />
                                        <Item name="underline" />
                                        <Item name="strike" />
                                        <Item name="separator" />
                                        <Item name="list" />
                                        <Item name="quote" />
                                        <Item name="separator" />
                                        <Item name="image" />
                                        <Item name="link" />
                                        <Item name="codeBlock" /> {/* To allow HTML editing */}

                                    </Toolbar>
                                    <ImageUpload uploadImage={uploadImage} />
                                    <MediaResizing />
                                </HtmlEditor>



                                <FormControl fullWidth margin="normal">
                                    <InputLabel id="categoria-label">Categoría</InputLabel>
                                    <Select
                                        labelId="categoria-label"
                                        id="categoria"
                                        name="categoriaId"
                                        value={formGuiaData?.categoriaId}
                                        onChange={handleChangeGuia}
                                        label="Categoría"
                                    >
                                        {categorias?.map((categoria) => (
                                            <MenuItem key={categoria._id} value={categoria._id}>
                                                {categoria.titulo}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        sx={{ marginRight: 1 }}
                                    >
                                        Enviar
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        onClick={() => setIsDrawerGuiaOpen(false)}
                                    >
                                        Cancelar
                                    </Button>
                                </Box>
                            </form>
                        </Box>
                    </Drawer>

                    <Grid style={{ display: 'flex', gap: '10px', alignItems: 'center', padding: 15 }}>
                        <FaCalendarAlt />
                        <Typography variant="h6" style={{ color: 'white' }}>
                            {catSeleccionada?.titulo?.length > 0 && guiasFiltradas?.length > 0 ? (
                                <>
                                    ÚLTIMAS GUIAS PUBLICADAS DE{' '}
                                    <span style={{ color: 'gold' }}>
                                        {catSeleccionada?.titulo?.toUpperCase()}
                                    </span>
                                </>
                            ) : (
                                'ÚLTIMAS GUIAS PUBLICADAS'
                            )}
                        </Typography>

                    </Grid>

                    {
                        guiasFiltradas?.length > 0 ?
                            guiasFiltradas?.map((guia, index) => (
                                <Box
                                    key={guia?._id}

                                    style={{ display: 'flex', gap: '20px', alignItems: 'center', padding: 10, borderBottom: '1px solid rgb(42, 46, 56)', borderTop: '1px solid rgb(42, 46, 56)' }}
                                >
                                    {/* <TbHelpHexagonFilled style={{ fontSize: '2.5rem' }} /> */}

                                    <Grid style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', width: '100%', gap: '15px' }}>
                                        <Grid>
                                            <img src={guia?.imagen ?? foto1} style={{ width: '150px', height: '150px', objectFit: 'cover', transition: 'transform 0.3s ease, filter 0.3s ease' }} className='img-efecto' />
                                        </Grid>
                                        <Grid style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', width: '80%' }}>
                                            <Typography variant="h6" onClick={() => handleClick(guia?._id)} style={{ cursor: 'pointer', color: 'gold' }}>{guia?.categoria?.titulo}
                                            </Typography>
                                            <Typography variant="h4" onClick={() => handleClick(guia?._id)} style={{ cursor: 'pointer' }}>{guia?.titulo}
                                            </Typography>
                                            <div className='custom-excerpt' style={{
                                                color: '#9ba5b4',
                                                fontSize: '13px',
                                            }} dangerouslySetInnerHTML={{ __html: excerpt(guia?.cuerpo) }} />

                                        </Grid>

                                        {
                                            (user?.username === 'drako' || user?.username === 'eldenguee') && (
                                                <Grid style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                                                    <IconButton aria-label="edit" onClick={() => handleEditGuia(guia)}>
                                                        <FaEdit style={{ color: 'white', fontSize: '16px' }} />
                                                    </IconButton>
                                                    <IconButton aria-label="delete" onClick={() => handleDeleteGuia(guia)}>
                                                        <FaTrash style={{ color: 'white', fontSize: '16px' }} />
                                                    </IconButton>
                                                </Grid>
                                            )
                                        }
                                    </Grid>
                                </Box>
                            ))
                            :
                            guias?.map((guia, index) => (


                                <Box
                                    key={guia?._id}

                                    style={{ display: 'flex', gap: '20px', alignItems: 'center', padding: 10, borderBottom: '1px solid rgb(42, 46, 56)', borderTop: '1px solid rgb(42, 46, 56)' }}
                                >
                                    {/* <TbHelpHexagonFilled style={{ fontSize: '2.5rem' }} /> */}

                                    <Grid style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', width: '100%', gap: '15px' }}>
                                        <Grid style={{borderRadius:'10px'}}>
                                            <img src={guia?.imagen ?? foto1} style={{ width: '150px', height: '150px', objectFit: 'cover', transition: 'transform 0.3s ease, filter 0.3s ease', borderRadius:'10px' }} className='img-efecto' />
                                        </Grid>
                                        <Grid style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', width: '80%' }}>
                                            <Typography variant="h6" onClick={() => handleClick(guia?._id)} style={{ cursor: 'pointer', color: 'gold' }}>{guia?.categoria?.titulo}
                                            </Typography>
                                            <Typography variant="h4" onClick={() => handleClick(guia?._id)} style={{ cursor: 'pointer' }}>{guia?.titulo}
                                            </Typography>
                                            <div className="custom-excerpt" dangerouslySetInnerHTML={{ __html: excerpt(guia?.cuerpo) }} />

                                        </Grid>

                                        {
                                            (user?.username === 'drako' || user?.username === 'eldenguee') && (
                                                <Grid style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                                                    <IconButton aria-label="edit" onClick={() => handleEditGuia(guia)}>
                                                        <FaEdit style={{ color: 'white', fontSize: '16px' }} />
                                                    </IconButton>
                                                    <IconButton aria-label="delete" onClick={() => handleDeleteGuia(guia)}>
                                                        <FaTrash style={{ color: 'white', fontSize: '16px' }} />
                                                    </IconButton>
                                                </Grid>
                                            )
                                        }
                                    </Grid>
                                </Box>
                            ))}


                    {/* Drawer de Confirmación guia */}
                    <Drawer
                        anchor="right"
                        open={openDeleteGuia}
                        onClose={handleGuiaDialogClose}
                    >
                        <Box sx={{ width: 300, padding: 2 }}>
                            <Typography variant="h6" gutterBottom>
                                Confirmar Eliminación
                            </Typography>
                            <Divider sx={{ marginBottom: 2 }} />
                            <Typography variant="body1" gutterBottom>
                                ¿Estás seguro de que quieres eliminar la guía <strong>{guiaSelect?.titulo}</strong>?
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                Esta acción no se puede deshacer. La guía y toda la información relacionada se eliminarán permanentemente.
                            </Typography>
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 3 }}>
                                <Button onClick={handleGuiaDialogClose} variant="outlined" color="primary" sx={{ marginRight: 1 }}>
                                    Cancelar
                                </Button>
                                <Button onClick={handleDeleteConfirmGuia} variant="contained" color="secondary">
                                    Eliminar
                                </Button>
                            </Box>
                        </Box>
                    </Drawer>



                    {/* Drawer para Editar */}
                    <Drawer
                        anchor="right"
                        open={openEditGuia}
                        onClose={handleDrawerEditGuiaClose}
                    >
                        <Box sx={{ width: 800, padding: 2 }}>
                            <Typography variant="h6" gutterBottom>
                                Editar Guia
                            </Typography>
                            <Divider sx={{ marginBottom: 2 }} />
                            <TextField
                                label="Título"
                                name="titulo"
                                value={formEditGuia?.titulo !== '' ? formEditGuia?.titulo : guiaEdit?.titulo}
                                onChange={(e) => handleInputChangeGuia2(e)}
                                fullWidth
                                margin="normal"
                            />
                            <TextField
                                label="Imagen Destacada"
                                name="imagen"
                                value={formEditGuia?.imagen !== '' ? formEditGuia?.imagen : guiaEdit?.imagen}
                                onChange={(e) => handleInputChangeGuia2(e)}
                                fullWidth
                                margin="normal"
                            />

                            <HtmlEditor
                                value={formEditGuia?.cuerpo !== '' ? formEditGuia?.cuerpo : guiaEdit?.cuerpo}
                                name='cuerpo'
                                height={600} // Optional: set height of the editor
                                onValueChanged={handleInputChangeGuia}

                            >
                                <Toolbar>
                                    <Item name="undo" />
                                    <Item name="codeBlock" /> {/* To allow HTML editing */}

                                    <Item name="redo" />
                                    <Item name="separator" />
                                    <Item name="bold" />
                                    <Item name="italic" />
                                    <Item name="underline" />
                                    <Item name="strike" />
                                    <Item name="separator" />
                                    <Item name="list" />
                                    <Item name="quote" />
                                    <Item name="separator" />
                                    <Item name="image" />
                                    <Item name="link" />
                                </Toolbar>
                                <ImageUpload uploadImage={uploadImage} fileUploadMode="base64" />
                                <MediaResizing enabled={true} />
                            </HtmlEditor>


                            <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 3 }}>
                                <Button onClick={handleDrawerEditGuiaClose} variant="outlined" color="primary" sx={{ marginRight: 1 }}>
                                    Cancelar
                                </Button>
                                <Button onClick={handleSaveGuia} variant="contained" color="secondary">
                                    Guardar
                                </Button>
                            </Box>
                        </Box>
                    </Drawer>
                </Grid>
            </Grid>

        </Layout >

    )
}

export default Ayuda