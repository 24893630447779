import React, { useEffect, useState } from 'react'
import Layout from '../layout/Layout'
import { Button, Dialog, DialogActions, DialogContent, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import './micuenta.css'
import sm from './sm.png';
import mg from './mg.png';
import elf from './elf.png';
import dl from './dl.png';
import bk from './bk.jpg';
import logoFooter from './logo.png'
import axios from 'axios';
import config from '../../config';
import { useParams } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns'; // Importamos el formateador de fechas
function Micuenta(props) {
    const [open, setOpen] = useState(false);
    const [selectedImg, setSelectedImg] = useState('');
    const [selectedCharacter, setSelectedCharacter] = useState(null);
    const [character, setCharacter] = useState(null);
    const [userInfo, setUserInfo] = useState(null);
    const handleClickOpen = (imgSrc, characterData) => {
        setSelectedImg(imgSrc);
        setSelectedCharacter(characterData);
        setOpen(true);
    };
    const { usuario } = useParams()

    const handleClose = () => {
        setOpen(false);
        setSelectedImg('');
        setSelectedCharacter(null);
    };

    const fetchNews = async () => {
        try {
            const response = await axios.get(`https://backmu.vercel.app/infoUser/user/${usuario}`);
            // Ordenar las noticias por la fecha de creación en orden descendente (primero la más reciente)
            setCharacter(response.data.personajes)
            setUserInfo(response.data.usuario)
        } catch (error) {
            console.error('Error al obtener noticias:', error);
        }
    };

    useEffect(() => {
        fetchNews()
    }, [usuario])

    const parseDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.getMonth() + 1; // Los meses empiezan desde 0
        const year = date.getFullYear();

        // Devuelve la fecha en formato "DD/MM/YYYY"
        return `${day}/${month}/${year}`;
    };

    const [logs, setLogs] = useState()
    const [filteredLogs, setFilteredLogs] = useState(logs);
    const [userFilter, setUserFilter] = useState('');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const getLogsPayments = async () => {

        try {
            const response = await axios.get('https://backmu.vercel.app/infoUser/logs');
            setLogs(response.data);
        } catch (error) {
            console.error('Error al obtener categorias:', error);
        }
    };

    useEffect(() => {
        getLogsPayments()
    }, [])

    const handleFilter = () => {
        let filtered = logs;

        if (userFilter) {
            filtered = filtered.filter((log) =>
                log.user?.toLowerCase().includes(userFilter.toLowerCase())
            );
        }

        if (startDate) {
            filtered = filtered.filter((log) =>
                new Date(log.payment_date) >= new Date(startDate)
            );
        }

        if (endDate) {
            filtered = filtered.filter((log) =>
                new Date(log.payment_date) <= new Date(endDate)
            );
        }

        setFilteredLogs(filtered);
    };

    const resetFilters = () => {
        setUserFilter('');
        setStartDate(null);
        setEndDate(null);
        setFilteredLogs(logs);
    };

    return (
        <Layout tyExpanded={props.tyExpanded} setExpanded={props.setExpanded} isMobile={props.isMobile}>
            <Grid style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: 15, gap: '20px', justifyContent: 'center' }}>

                <Grid style={{ display: 'flex', flexDirection: 'column', padding: 5, borderRadius: '5px', borderColor: '1px solid rgb(42, 46, 56) ', width: '70%', justifyContent: 'center', margin: '0 auto', background: '#121418' }} >
                    <Grid style={{ display: 'flex', justifyContent: 'space-between', width: '100%', padding: 5 }} className='infoOnline' >
                        <Typography style={{ color: 'white' }} >PERSONAJES E INFORMACIÓN</Typography>
                    </Grid>

                    <Grid style={{ display: 'flex', width: '100%', padding: 5, gap: '5px', flexWrap: props.isMobile && 'wrap' }}>


                        {[
                            ...(character && Array.isArray(character) ? character.slice(0, 5) : []),
                            ...new Array(5 - (character && Array.isArray(character) ? character.length : 0)).fill({ Class: null, imgSrc: logoFooter, Name: '---' })
                        ].map((character, index) => {
                            let imageSrc;

                            // Condición para determinar qué imagen mostrar según character.Class
                            if ([0, 1, 2].includes(character.Class)) {
                                imageSrc = sm; // Reemplazar con la URL de la imagen A
                            } else if ([16, 17, 18].includes(character.Class)) {
                                imageSrc = bk; // Reemplazar con la URL de la imagen B
                            } else if ([64, 65, 66].includes(character.Class)) {
                                imageSrc = dl; // Reemplazar con la URL de la imagen C
                            } else if ([32, 33, 34].includes(character.Class)) {
                                imageSrc = elf; // Reemplazar con la URL de la imagen D
                            } else if ([48, 49, 50].includes(character.Class)) {
                                imageSrc = mg; // Reemplazar con la URL de la imagen E
                            } else {
                                imageSrc = character.imgSrc ?? logoFooter; // Usar la imagen por defecto si no entra en ninguna de las condiciones anteriores
                            }

                            // Verificar si el character es un objeto de relleno (con Class null y Nombre "No disponible")
                            const isPlaceholder = character.Class === null;

                            return (
                                <Grid
                                    key={index}
                                    style={{ display: 'flex', flexDirection: 'column', gap: '5px', cursor: 'pointer', flex: props.isMobile && '1 1 calc(50% - 16px)' }}
                                    className='img-cuenta'
                                    // Solo asignamos el onClick si no es un objeto de relleno
                                    onClick={!isPlaceholder ? () => handleClickOpen(imageSrc, character) : undefined}
                                >
                                    <img src={imageSrc} alt='Character' style={{ borderRadius: '5px', width: props.isMobile && '80%', margin: props.isMobile && '0 auto' }} />
                                    <Typography style={{ color: 'white', textAlign: 'center' }}>{character.Name}</Typography>
                                </Grid>
                            );
                        })}



                    </Grid>

                    <Grid style={{ width: '100%', display: 'flex', flexDirection: props.isMobile && 'column', alignItems: 'center', border: '1px solid rgb(42, 46, 56)' }}>
                        <Grid style={{ padding: 5, borderRight: '1px solid rgb(42, 46, 56)', width: props.isMobile ? '100%' : '15%', textAlign: props.isMobile && 'center' }}>
                            <Typography style={{ color: 'white' }}>E-mail:</Typography>
                        </Grid>
                        <Grid style={{ padding: 5 }}>
                            <Typography style={{ color: 'white' }}>{userInfo?.email}</Typography>
                        </Grid>
                    </Grid>
                    <Grid style={{ width: '100%', display: 'flex', alignItems: 'center', border: '1px solid rgb(42, 46, 56)', flexDirection: props.isMobile && 'column' }}>
                        <Grid style={{ padding: 5, borderRight: '1px solid rgb(42, 46, 56)', width: props.isMobile ? '100%' : '15%', textAlign: props.isMobile && 'center' }}>
                            <Typography style={{ color: 'white' }}>Cuenta creada:</Typography>
                        </Grid>
                        <Grid style={{ padding: 5 }}>
                            <Typography style={{ color: 'white' }}>{parseDate(userInfo?.CreateDate)}</Typography>
                        </Grid>
                    </Grid>
                    <Grid style={{ width: '100%', display: 'flex', alignItems: 'center', border: '1px solid rgb(42, 46, 56)', flexDirection: props.isMobile && 'column' }}>
                        <Grid style={{ padding: 5, borderRight: '1px solid rgb(42, 46, 56)', width: props.isMobile ? '100%' : '15%', textAlign: props.isMobile && 'center' }}>
                            <Typography style={{ color: 'white' }}>Último acceso:</Typography>
                        </Grid>
                        <Grid style={{ padding: 5 }}>
                            <Typography style={{ color: 'white' }}>{parseDate(userInfo?.recovery_date)}</Typography>
                        </Grid>
                    </Grid>
                    {
                        userInfo?.ctl1_code === 1 && (
                            <Grid style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', backgroundColor: '#660000', border: '1px solid #330000' }}>
                                <Grid style={{ padding: 5 }}>
                                    <Typography style={{ color: 'white' }}>Cuenta Baneada:</Typography>
                                </Grid>
                                <Grid style={{ padding: 5 }}>
                                    <Typography style={{ color: 'white' }}>Cuenta Baneada hasta:</Typography>
                                </Grid>

                            </Grid>
                        )
                    }


                </Grid>




                {usuario === 'pety456' && (
                    <div style={{ margin: '0 auto', width: '80%' }}>
                        <Grid container spacing={2} alignItems="center" style={{ marginBottom: '20px' }}>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    label="Usuario"
                                    value={userFilter}
                                    onChange={(e) => setUserFilter(e.target.value)}
                                    fullWidth
                                    InputLabelProps={{ style: { color: 'white' } }}
                                    InputProps={{ style: { color: 'white' } }}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: 'white',
                                            },
                                            '&:hover fieldset': {
                                                borderColor: '#90caf9',
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: '#90caf9',
                                            },
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3} style={{ width: props.isMobile && '100%' }}>
                                <DatePicker
                                    selected={startDate}
                                    onChange={(date) => setStartDate(date)}
                                    placeholderText="Fecha inicio"
                                    dateFormat="yyyy-MM-dd"
                                    customInput={<TextField label="Fecha inicio" fullWidth InputLabelProps={{ style: { color: 'white' } }}
                                        InputProps={{ style: { color: 'white' } }}
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    borderColor: 'white',
                                                },
                                                '&:hover fieldset': {
                                                    borderColor: '#90caf9',
                                                },
                                                '&.Mui-focused fieldset': {
                                                    borderColor: '#90caf9',
                                                },
                                            },
                                        }} />}
                                    style={{ width: props.isMobile && '100%' }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <DatePicker
                                    selected={endDate}
                                    onChange={(date) => setEndDate(date)}
                                    placeholderText="Fecha fin"
                                    dateFormat="yyyy-MM-dd"
                                    customInput={<TextField label="Fecha fin" fullWidth InputLabelProps={{ style: { color: 'white' } }}
                                        InputProps={{ style: { color: 'white' } }}
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    borderColor: 'white',
                                                },
                                                '&:hover fieldset': {
                                                    borderColor: '#90caf9',
                                                },
                                                '&.Mui-focused fieldset': {
                                                    borderColor: '#90caf9',
                                                },
                                            },
                                        }} />}
                                />
                            </Grid>
                            <Grid item xs={12} sm={2} style={{ display: 'flex', gap: '10px' }}>
                                <Button variant="contained" color="primary" onClick={handleFilter}>
                                    Filtrar
                                </Button>
                                <Button variant="outlined" onClick={resetFilters}>
                                    Reiniciar
                                </Button>
                            </Grid>
                        </Grid>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ color: 'white', textAlign: 'center' }}>ID</TableCell>
                                        <TableCell style={{ color: 'white', textAlign: 'center' }}>Usuario</TableCell>
                                        <TableCell style={{ color: 'white', textAlign: 'center' }}>Fecha</TableCell>
                                        <TableCell style={{ color: 'white', textAlign: 'center' }}>Cantidad Pagada</TableCell>
                                    </TableRow>
                                </TableHead>

                                {filteredLogs?.length ?
                                    <TableBody>
                                        {filteredLogs?.map((log) => (
                                            <TableRow key={log.id}>
                                                <TableCell>{log.id}</TableCell>
                                                <TableCell>{log.external_reference}</TableCell>
                                                <TableCell>
                                                    {log.payment_date ? format(new Date(log.payment_date), 'dd/MM/yyyy') : ''}
                                                </TableCell>
                                                <TableCell>{log.amount}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>

                                    :
                                    <TableBody>
                                        {logs?.map((log) => (
                                            <TableRow key={log.id}>
                                                <TableCell style={{textAlign:'center'}}>{log.id}</TableCell>
                                                <TableCell style={{textAlign:'center'}}>{log.external_reference}</TableCell>
                                                <TableCell style={{textAlign:'center'}}>
                                                    {log.payment_date ? format(new Date(log.payment_date), 'dd/MM/yyyy') : ''}
                                                </TableCell>
                                                <TableCell style={{textAlign:'center'}}s>{log.amount}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>

                                }



                            </Table>
                        </TableContainer>
                    </div>
                )}
            </Grid>

            <Dialog open={open} onClose={handleClose} maxWidth='lg' BackdropProps={{
                style: {
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    backdropFilter: 'blur(10px)', // Apply blur effect
                    width: '110% !important',
                },
            }}>
                <Grid style={{ display: 'flex', justifyContent: 'space-between', width: '100%', padding: 5, background: "#333333" }}  >
                    <Typography style={{ color: 'white' }} >INFORMACIÓN DE PERSONAJE</Typography>
                </Grid>
                <DialogContent style={{ display: 'flex', flexDirection: props.isMobile ? 'column' : 'row', backgroundColor: '#121418' }}>

                    <Grid item xs={4} style={{ paddingRight: '15px' }}>
                        <img src={selectedImg} alt='Character' style={{ width: '100%', borderRadius: '5px' }} />
                        <Typography style={{ color: 'white', textAlign: 'center', marginTop: '10px', fontSize: '24px' }}>
                            {selectedCharacter?.Name}
                        </Typography>
                        <Typography style={{ color: 'rgb(155, 165, 180)', textAlign: 'center', marginTop: '5px' }}>
                            {selectedCharacter?.ResetCount} resets / Nivel {selectedCharacter?.cLevel}
                        </Typography>
                        <Typography style={{ color: 'rgb(155, 165, 180)', textAlign: 'center', marginTop: '5px' }}>
                            (
                            {
                                selectedCharacter?.Class === 16 || selectedCharacter?.Class === 17 || selectedCharacter?.Class === 18 ? 'Blade Knight'
                                    :
                                    selectedCharacter?.Class === 64 || selectedCharacter?.Class === 65 || selectedCharacter?.Class === 66 ? 'Dark Lord'
                                        :
                                        selectedCharacter?.Class === 32 || selectedCharacter?.Class === 33 || selectedCharacter?.Class === 34 ? 'Muse Elf'
                                            :
                                            selectedCharacter?.Class === 48 || selectedCharacter?.Class === 49 || selectedCharacter?.Class === 50 ? 'Magic Gladiator'
                                                :
                                                selectedCharacter?.Class === 0 || selectedCharacter?.Class === 1 || selectedCharacter?.Class === 2 ? 'Soul Master'
                                                    :
                                                    ''
                            }
                            )
                        </Typography>
                    </Grid>


                    <Grid item xs={8} style={{ paddingLeft: '15px' }}>
                        {selectedCharacter && (
                            <Grid container spacing={2} style={{ padding: '10px', borderRadius: '5px', height: '100%' }}>
                                {/* Stat Row 1 */}
                                <Grid item xs={6} style={{ border: '1px solid rgb(42, 46, 56)' }}>
                                    <Typography style={{ color: 'rgb(155, 165, 180)' }}>Fuerza: {selectedCharacter?.Strength}</Typography>
                                </Grid>
                                <Grid item xs={6} style={{ border: '1px solid rgb(42, 46, 56)' }}>
                                    <Typography style={{ color: 'rgb(155, 165, 180)' }}>Guild:</Typography>
                                </Grid>

                                {/* Stat Row 2 */}
                                <Grid item xs={6} style={{ border: '1px solid rgb(42, 46, 56)' }}>
                                    <Typography style={{ color: 'rgb(155, 165, 180)' }}>Agilidad: {selectedCharacter?.Dexterity} </Typography>
                                </Grid>
                                <Grid item xs={6} style={{ border: '1px solid rgb(42, 46, 56)' }}>
                                    <Typography style={{ color: 'rgb(155, 165, 180)' }}>Muertes: {selectedCharacter?.Deads} </Typography>
                                </Grid>

                                {/* Stat Row 3 */}
                                <Grid item xs={6} style={{ border: '1px solid rgb(42, 46, 56)' }}>
                                    <Typography style={{ color: 'rgb(155, 165, 180)' }}>Vitalidad: {selectedCharacter?.Vitality}</Typography>
                                </Grid>
                                <Grid item xs={6} style={{ border: '1px solid rgb(42, 46, 56)' }}>
                                    <Typography style={{ color: 'rgb(155, 165, 180)' }}>Costo PK: {60000000 + 1500000 * selectedCharacter?.Deads}</Typography>
                                </Grid>

                                {/* Stat Row 4 */}
                                <Grid item xs={6} style={{ border: '1px solid rgb(42, 46, 56)' }}>
                                    <Typography style={{ color: 'rgb(155, 165, 180)' }}>Energía: {selectedCharacter?.Energy}</Typography>
                                </Grid>
                                <Grid item xs={6} style={{ border: '1px solid rgb(42, 46, 56)' }}>
                                    <Typography style={{ color: 'rgb(155, 165, 180)' }}>Puntos PK: {selectedCharacter?.PkPoints_EXT}</Typography>
                                </Grid>

                                {/* Stat Row 5 */}
                                <Grid item xs={6} style={{ border: '1px solid rgb(42, 46, 56)' }}>
                                    <Typography style={{ color: 'rgb(155, 165, 180)' }}>Puntos: {selectedCharacter?.LevelUpPoint}</Typography>
                                </Grid>
                                <Grid item xs={6} style={{ border: '1px solid rgb(42, 46, 56)' }}>
                                    <Typography style={{ color: 'rgb(155, 165, 180)' }}>Zen: {selectedCharacter?.Money}</Typography>
                                </Grid>



                            </Grid>
                        )}

                    </Grid>
                </DialogContent>

                <DialogActions style={{ justifyContent: 'center', backgroundColor: '#121418' }}>
                    <Button onClick={handleClose} style={{ background: '#333333', width: '100%' }} variant='contained' className='btn-cerrar' >
                        Cerrar
                    </Button>
                </DialogActions>
            </Dialog>
        </Layout>
    )
}

export default Micuenta