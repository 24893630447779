import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios'
import config from './config';

// Creamos el contexto de autenticación
const AuthContext = createContext();

// Creamos un componente que proveerá el contexto de autenticación a los componentes hijos
export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);

    async function verifyToken(account, token) {
        try {
            const response = await axios.post(`${config.apiBaseUrl}/verificartoken`, {
                account,
                token
            });
            return response.data;
        } catch (error) {
            return {
                error
            }
        }
    }

    useEffect(() => {
        async function getUser() {
            let token = window.localStorage.getItem("token");
            let account = window.localStorage.getItem("account");

            if (token && account) {
                let res = await verifyToken(account, token);
                if (res.success) {
                    setUser({ username: account });
                } else {
                    console.error('Error en la verificación del token:', res.error);
                    // Manejar el logout si es necesario
                }
            }
        }

        getUser();
    }, []);

    return (
        <AuthContext.Provider value={{ user, setUser }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;
