import React, { useEffect } from 'react';
import { getImageForClass, drawFlag, convertGMarkToHex } from '../../utils';
import './tabla.css';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Grid } from '@mui/material';
function TablaSDLF({ jugadores }) {
  useEffect(() => {
    const renderFlags = () => {
      jugadores.forEach((jugador) => {
        if (jugador.G_Name !== '--') {
          const canvasId = `flag-sdlf-${jugador.Orden}`;
          const canvas = document.getElementById(canvasId);
          const context = canvas.getContext('2d');

          // Limpiar el canvas
          context.clearRect(0, 0, canvas.width, canvas.height);

          // Dibujar los nuevos flags
          drawFlag(canvasId, convertGMarkToHex(jugador.G_Mark));
        }
      });
    };

    renderFlags();

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        renderFlags();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [jugadores]);

  const maxOrden = Math.max(...jugadores.map(jugador => jugador.Orden), 0);
  const nuevoOrden = maxOrden + 1;

  // Función para formatear la fecha en DD-MM-AAAA HH:MM
  const formatDate = (dateString) => {
    const date = new Date(dateString);

    date.setHours(date.getHours() + 4);

    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${day}-${month}-${year} ${hours}:${minutes}`;
  };

  return (
    <TableContainer component={Paper}>
      <Table className='table'>
        <TableHead>
          <TableRow>
            <TableCell style={{ color: 'white', textAlign: 'center', textTransform: 'uppercase' }}>#</TableCell>
            <TableCell style={{ color: 'white', textAlign: 'center', textTransform: 'uppercase' }}>Personaje</TableCell>
            <TableCell style={{ color: 'white', textAlign: 'center', textTransform: 'uppercase' }}>Clase</TableCell>
            <TableCell style={{ color: 'white', textAlign: 'center', textTransform: 'uppercase' }}>Clan</TableCell>
            <TableCell style={{ color: 'white', textAlign: 'center', textTransform: 'uppercase' }}>Maxed desde</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {jugadores.map((jugador) => (
            <TableRow key={jugador.Orden}>
              <TableCell style={{ color: '#cdcdcd' }}>{jugador.Orden}</TableCell>
              <TableCell style={{ color: '#cdcdcd' }}>
                <div className={jugador.ConnectStat === 1 ? 'connected' : 'disconnected'}>
                  <div className="player-container">
                    <Grid style={{display:'flex' , justifyContent:'flex-start', alignItems:'center', width:'100%'}}>

                      {
                        jugador.ConnectStat === 1 &&
                          <span id="pulsatingDot" style={{ marginRight: '5px' }} />
                          
                      }

                      <span style={{ color: jugador.ConnectStat === 1 ? 'green': '#cdcdcd', textAlign: 'center'}}>{jugador.Name}</span>
                      {jugador.IsMaster === 1 && <img src="assets/img/star.gif" alt="Guild Master" title ="Es Guild Master" style={{ width: '12px', height: '12px', border: 'none', marginLeft: '4px' }} />}
                    </Grid>
                  </div>
                </div>
              </TableCell>
              <TableCell style={{ color: '#cdcdcd' }}>
                <img src={`assets/img/${getImageForClass(jugador.Class)}`} width="30" alt="Conectado" title={jugador.Class} style={{ marginRight: '8px' }} />
              </TableCell>
              <TableCell style={{ color: '#cdcdcd' }}>
                {jugador.G_Name !== '--' && (
                  <div className="clan-container">
                    <canvas className="canvas-clan" id={`flag-sdlf-${jugador.Orden}`} width="24" height="24" data-flag={convertGMarkToHex(jugador.G_Mark)} style={{ marginRight: '8px' }}></canvas>
                    <span style={{ color: '#cdcdcd' }}>{jugador.G_Name}</span>
                  </div>
                )}
              </TableCell>
              <TableCell style={{ color: '#cdcdcd' }}>{formatDate(jugador.Fecha)}</TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell style={{ color: '#cdcdcd' }}>{nuevoOrden}</TableCell>
            <TableCell colSpan={4} style={{ textAlign: 'center', color: '#cdcdcd' }}>¡¡PODRÍAS SER VOS!!</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default TablaSDLF;
