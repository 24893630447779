import React, { useEffect, useState } from 'react'
import Layout from '../layout/Layout'
import { Link, useParams } from 'react-router-dom'
import axios from 'axios'
import { Grid, Typography, Box, Breadcrumbs, Button } from '@mui/material'

import foto1 from './foto3.webp'
import { CgOverflow } from 'react-icons/cg'


function Guia(props) {
    const { id } = useParams()

    const [guia, setGuia] = useState()

    const [categorias, setCategorias] = useState();

    const searchGuia = async () => {
        try {
            const response = await axios.get(`https://backmu.vercel.app/ayudaGuia/${id}`);
            setGuia(response?.data)


        }
        catch (err) { console.error(err) }
    }

    // Función para obtener la lista de categorias
    const fetchCatGuias = async (id) => {
        const idCat = id?.categoria?._id

        try {
            const response = await axios.get(`https://backmu.vercel.app/guia/${idCat}`);
            setCategorias(response.data);
        } catch (error) {
            console.error('Error al obtener categorias:', error);
        }
    };
    useEffect(() => {
        searchGuia();

    }, [id])

    useEffect(() => {
        fetchCatGuias(guia)
    }, [guia])

    return (
        <Layout tyExpanded={props.tyExpanded} setExpanded={props.setExpanded} isMobile={props.isMobile}>


            <Grid style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'space-around', padding: 15, gap: '20px' }}>

                <Grid style={{ position: 'relative', width: '100%', height: '40%', display: 'flex', justifyContent: 'center' }}>
                    <div style={{ position: 'relative', width: '90%', height: '500px', borderRadius: '5px' }}>
                        <img
                            src={guia?.imagen ?? foto1}
                            style={{
                                width: '100%',
                                height: '100%',
                                borderRadius: '5px',

                                objectFit: 'cover'
                            }}
                        />
                        <div
                            style={{
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                width: '100%',
                                height: '100%',
                                background: 'linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0))',
                                borderRadius: '5px'
                            }}
                        />
                    </div>
                    <Grid style={{
                        position: 'absolute',
                        bottom: '30px', // Ajusta la distancia desde la parte inferior según lo desees
                        left: '50%',
                        transform: 'translateX(-50%)',
                        color: 'white', // Cambia el color según tu preferencia
                        textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)', // Añade sombra para mejorar la legibilidad
                        zIndex: 1
                    }}>

                        <Breadcrumbs aria-label="breadcrumb">
                            <Link color="inherit" to="/ayuda" style={{ color: 'white', textDecoration: 'none' }}>
                                Home
                            </Link>
                            <Link color="inherit" to="/ayuda" style={{ color: 'white', textDecoration: 'none' }} >
                                {guia?.categoria?.titulo}
                            </Link>
                            <Typography color="textPrimary" style={{ color: 'white', background: '#eb3f39', padding: 3, borderRadius: '5PX' }}>{guia?.titulo}</Typography>
                        </Breadcrumbs>
                        <Typography
                            variant='h4'
                            style={{ textAlign: 'center' }}
                        >
                            {guia?.titulo}
                        </Typography>
                    </Grid>

                </Grid>

                {/* <Grid style={{ display: 'flex', flexDirection: 'column', width: props?.isMobile ? '100%' : '25%', color: 'white', gap: ' 15px' }}>
                    <Grid style={{ display: 'flex', flexDirection: 'column', border: '1px solid #cd100a', borderRadius: '5px' }}>

                        {
                            categorias?.map((category) => {
                                
                                return (

                                    <Box key={category?._id} style={{ borderBottom: '1px solid #cd100a', padding: 10, display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer' }} >
                                        <Box>
                                            <Typography variant="h4">{category?.titulo}</Typography>
                                            <Typography variant="body">{category?.descripcion}</Typography>
                                        </Box>
                                    </Box>
                                )
                            })
                        }
                    </Grid>
                </Grid> */}


                <Grid style={{ display: 'flex', gap: '15px', justifyContent: 'center', width: '100%' }}>

                    <Grid style={{ display: 'flex', flexDirection: 'column', color: 'white', width: '65%', backgroundColor: '#161616', padding: '15px', borderRadius: '5px' }}>

                        <div dangerouslySetInnerHTML={{ __html: guia?.cuerpo }} />
                    </Grid>

                    <Grid style={{ width: '25%', display: 'flex', flexDirection: 'column', backgroundColor: '#161616', padding: '15px', borderRadius: '5px' }}>
                        <Grid style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                            <Typography style={{ color: 'white' }}>Recomendado</Typography>
                            <Link to={'/ayuda'} style={{ color: 'white', textDecoration: 'none' }}>Ver todo</Link>
                        </Grid>
                        {
                            categorias?.guias?.map((category) => {
                                console.log(category)
                                return (

                                    <Link to={`/guia/${category?._id}`} key={category?._id} style={{ padding: 10, display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer', width: '100%', textDecoration: 'none' }} >
                                        <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '10px', overflow: 'hidden', borderRadius: '5px' }}>

                                            <img src={category?.imagen ?? foto1} style={{ width: '80px', height: '80px', objectFit: 'cover', borderRadius: '5px' }} className='img-efecto' />
                                            <Grid style={{display:'flex', flexDirection:'column', width:'70%'}}>

                                                <Typography variant="h6" style={{ color: 'gold', fontSize: '12px' }}>{guia?.categoria?.titulo}</Typography>
                                                <Typography variant="h6" style={{ color: 'white', fontSize: '16px' }}>{category?.titulo}</Typography>

                                            </Grid>
                                        </Box>
                                    </Link>
                                )
                            })
                        }
                    </Grid>
                </Grid>

            </Grid>
        </Layout>

    )
}

export default Guia