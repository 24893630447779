import React from 'react';
import Layout from '../layout/Layout';
import { Grid, Typography, Link, Alert } from '@mui/material';
import { FaDownload } from 'react-icons/fa';
import { SiMediafire } from "react-icons/si";
import { SiMega } from "react-icons/si";
import './descargas.css';
import { Helmet } from 'react-helmet';

function Descargas(props) {
    return (
        <Layout tyExpanded={props.tyExpanded} setExpanded={props.setExpanded}>
            <Helmet>
                <title>Mu Serendipia | Descargas</title>
                <meta name="description" content="Descarga el cliente oficial de Mu Serendipia. Sigue nuestras instrucciones para una instalación segura y correcta." />
                <meta name="keywords" content="mu serendipia, descargas, cliente mu online, mediafire, mega, instalación juego" />
            </Helmet>
            <Grid style={{ display: 'flex', flexDirection: 'column', gap: '35px', padding: '2rem 5.8rem' }}>
                <Typography style={{ color: 'white', fontSize: '20px', display: 'flex', gap: '5px', alignItems: 'center', marginBottom: '20px', textAlign: 'justify' }}>
                    <FaDownload style={{ color: '#ffc107' }} />
                    Descargas
                </Typography>
                <div className="usersettings-settings">
                    <div className="usersettings-change-avatar">
                        <Typography style={{ color: 'white', marginBottom: '20px', textAlign: 'justify' }}>
                            ¡Bienvenido a nuestra sección de descargas! Aquí encontrarás los archivos necesarios para comenzar tu aventura en el continente de Mu Serendipia. Sigue las instrucciones del instalador para una correcta instalación del juego.
                        </Typography>
                    </div>
                    <div className="usersettings-change-avatar">
                        <Alert severity="warning" style={{ marginBottom: '20px' }}>
                            <div style={{ display: 'flex', alignItems: 'center', textAlign: 'justify' }}>
                                <Typography>Para garantizar la seguridad de tu equipo, te recomendamos descargar el cliente únicamente desde los enlaces proporcionados en esta sección. Evita descargar desde otras fuentes.</Typography>
                            </div>
                        </Alert>
                    </div>
                    <div className="usersettings-change-avatar">
                        <Typography style={{ color: 'white', textAlign: 'justify' }}>Descargar desde:</Typography>
                        <div className="download-links" style={{ display: 'flex', alignItems: 'center' }}>
                            <button className="download-button" style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                <SiMediafire style={{ color: '#0077ff', fontSize: '20px' }} />
                                <Link href="https://www.mediafire.com/file/cyijd5ksnsf83s2/MuSerendipia1024.exe/file" target="_blank" rel="noopener noreferrer">Mediafire</Link>
                            </button>
                            <button className="download-button" style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                <SiMega style={{ color: 'red', fontSize: '20px' }} />
                                <Link href="https://mega.nz/file/ViF0CIBS#KD89RgSL_HsSlfYMmO7HK9mIA1viB4XMmTmqS2YyOP0" target="_blank" rel="noopener noreferrer">Mega</Link>
                            </button>
                        </div>
                    </div>
                </div>
            </Grid>
        </Layout>
    );
}

export default Descargas;
