
export function getImageForClass(classNumber) {
    switch (classNumber) {
        case 0:
        case 1:
        case 2:
            return 'sm.png';
        case 16:
        case 17:
        case 18:
            return 'bk.png';
        case 32:
        case 33:
        case 34:
            return 'me.png';
        case 48:
        case 50:
            return 'mg.png';
        case 64:
        case 66:
            return 'dl.png';
        default:
            return 'Desconocido';
    }
}

export function drawFlag(canvasId, flagData) {
    var colors = {
        '0': 'rgba(0,0,0,0)',
        '1': 'rgb(0,0,0)',
        '2': 'rgb(128,128,128)',
        '3': 'rgb(255,255,255)',
        '4': 'rgb(254,0,0)',
        '5': 'rgb(255,127,0)',
        '6': 'rgb(255,255,0)',
        '7': 'rgb(128,255,0)',
        '8': 'rgb(0,255,1)',
        '9': 'rgb(0,254,129)',
        'A': 'rgb(0,255,255)',
        'B': 'rgb(0,128,255)',
        'C': 'rgb(0,0,254)',
        'D': 'rgb(127,0,255)',
        'E': 'rgb(255,0,252)',
        'F': 'rgb(255,0,128)'
    };

    var canvas = document.getElementById(canvasId);

    if (canvas.getContext) {
        var context = canvas.getContext('2d');
        var scale = canvas.width / 8;

        for (var i = 0; i < 64; i++) {
            var row = Math.floor(i / 8);
            var col = i % 8;
            var cell = flagData.charAt(i);

            var x = col * scale;
            var y = row * scale;

            context.fillStyle = colors[cell];
            context.fillRect(x, y, scale, scale);
        }
    }
}

export function convertGMarkToHex(gMark) {
    if (!gMark || typeof gMark !== 'string') return '';
    const hexString = gMark.startsWith('0x') ? gMark.slice(2) : gMark;
    return hexString;
}

export function convertMinutes(minutes) {
    if (minutes < 0) {
        return "0h 0m";
    }

    const days = Math.floor(minutes / (60 * 24));
    const hours = Math.floor((minutes % (60 * 24)) / 60);
    const remainingMinutes = minutes % 60;

    let timeString = '';
    if (days > 0) {
        timeString += `${days}d `;
    }
    if (hours > 0 || days > 0) {
        timeString += `${hours}h `;
    }
    timeString += `${remainingMinutes}m`;

    return timeString.trim();
}

export function getPkLevelString(pkLevel) {
    switch (pkLevel) {
        case 1:
        case 2:
            return 'Hero';
        case 3:
            return 'Commoner';
        case 4:
            return 'Murderer';
        case 5:
        case 6:
            return 'Phonomania';
        default:
            return 'Desconocido';
    }
}

export function getPkLevelColor(pkLevel) {
    switch (pkLevel) {
        case 1:
            return '#6478ff';
        case 2:
            return '#8cb4ff';
        case 3:
            return '#c8dcff';
        case 4:
            return '#ff963c';
        case 5:
        case 6:
            return '#ff501e';
        default:
            return '#ffffff';
    }
}
