import React, { useEffect, useState } from 'react'
import Layout from '../layout/Layout'
import { Button, Grid, Typography } from '@mui/material'
import axios from 'axios';
import { MdDeleteForever } from "react-icons/md";
import { FaEdit, FaInfoCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

function Foro(props) {
    const [user, setUser] = useState({});

    const [temas, setTemas] = useState([]);
    const [nuevoTema, setNuevoTema] = useState({
        idUsuario: 0,
        titulo: '',
        descripcion: '',
    });
    const [abrirCrear, setAbrirCrear] = useState()
    const handleChange = (event) => {
        const { name, value } = event.target;
        setNuevoTema(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    // Función para obtener la lista de noticias
    const fetchTemas = async () => {
        try {
            const response = await axios.get('https://backmu.vercel.app/temas');
            setTemas(response.data);
        } catch (error) {
            console.error('Error al obtener noticias:', error);
        }
    };
    // Efecto para cargar la lista de noticias cuando el componente se monta
    useEffect(() => {
        fetchTemas();
    }, []);

  
    // Función para enviar la solicitud de creación de noticia
    const handleCreateTema = async () => {
        try {
            const response = await axios.post('https://backmu.vercel.app/temas', nuevoTema);
            console.log(response.data); // Deberías recibir el mensaje indicando que la noticia se creó exitosamente
            // Actualiza la lista de noticias después de crear una nueva
            fetchTemas();
            // Reinicia el formulario
            setNuevoTema({ title: '', content: '', author: '' });
            setAbrirCrear(!abrirCrear)
        } catch (error) {
            console.error('Error al crear tema:', error);
        }
    };

    const handleDeleteTemas = async (id) => {
        try {
            const response = await axios.delete(`https://backmu.vercel.app/temas/${id}`);
            console.log(response.data); // Deberías recibir el mensaje indicando que la noticia se eliminó exitosamente
            // Actualiza la lista de noticias después de eliminar una noticia
            fetchTemas();
        } catch (error) {
            console.error('Error al eliminar el tema:', error);
        }
    };
    return (
        <Layout tyExpanded={props.tyExpanded} setExpanded={props.setExpanded} isMobile={props.isMobile}>
            <Helmet>
                <title>Mu Serendipia | Foro</title>
                <meta name="description" content="Bienvenido al Foro de Mu Serendipia, donde puedes discutir y compartir temas relacionados con el juego." />
                <meta name="keywords" content="foro mu serendipia, temas de discusión, mu online, comunidad de jugadores" />
            </Helmet>

            <Grid style={{ display: 'flex', flexDirection: 'column', gap: '35px', padding: props.isMobile ? '2rem 1rem' : '2rem 5.8rem' }}>
                <Grid style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

                    <Typography style={{ color: 'white', fontStyle: 'italic', fontSize: '20px' }}>BIENVENIDOS AL FORO MU SERENDIPIA</Typography>


                    {
                        user.rol === 'admin' ?

                            <Grid style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
                                <Typography style={{ color: 'black', borderRadius: '5px', backgroundColor: 'white', padding: '5px', cursor: 'pointer' }} onClick={() => setAbrirCrear(!abrirCrear)} >Crear Tema</Typography>
                            </Grid>

                            : <></>
                    }

                </Grid>


                {
                    abrirCrear ?

                        <Grid style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
                            <Grid style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
                                <label style={{ color: 'white' }} htmlFor="titulo">Título:</label>
                                <input
                                    type="text"
                                    id="titulo"
                                    name="titulo"
                                    value={nuevoTema.titulo}
                                    onChange={(e) => setNuevoTema({ ...nuevoTema, idUsuario: user._id, titulo: e.target.value })}
                                    required
                                />
                            </Grid>

                            <Grid style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
                                <label style={{ color: 'white' }} htmlFor="descripcion">Desripcion:</label>
                                <textarea
                                    id="descripcion"
                                    name="descripcion"
                                    value={nuevoTema.descripcion}
                                    onChange={(e) => setNuevoTema({ ...nuevoTema, idUsuario: user._id, descripcion: e.target.value })}
                                    required
                                />
                            </Grid>
                            <Button onClick={handleCreateTema}>Crear</Button>
                        </Grid>

                        :

                        <></>
                }


                {
                    <Grid style={{ display: 'flex', borderRadius: '5px', border: '1px solid grey', padding: '10px', flexDirection: 'column', gap: '10px' }}>

                        {temas.length > 0 ?

                            temas.map((tema, index) => {

                                const parsedDate = new Date(tema?.posts[0]?.fecha_creacion)

                                const formattedDate = `${parsedDate.getDate()}/${parsedDate.getMonth() + 1}/${parsedDate.getFullYear()} `;
                                return (
                                    <Link to={`/foro/${tema._id}`} style={{ textDecoration: 'none', display: 'flex', gap: '5px', padding: '10px', border: '1px solid gray', width: '100%', borderRadius: '5px', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Grid style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '15px', width: '50%' }}>
                                            <FaInfoCircle style={{ color: 'white', fontSize: '2.5rem' }} />

                                            <Grid style={{ display: 'flex', flexDirection: 'column', gap: '2px' }}>
                                                <Typography style={{ color: 'white', fontSize: '18px' }}>{tema.titulo?.toUpperCase()}</Typography>
                                                <Typography style={{ color: 'white' }}>{tema.descripcion}</Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid style={{ display: 'flex', flexDirection: 'column', gap: '5px', alignItems: 'center' }}>
                                            <Typography style={{ color: 'white' }}>Posts</Typography>
                                            <Typography style={{ color: 'white' }}>{tema.posts?.length}</Typography>
                                        </Grid>

                                        <Grid style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '15px', padding: '10px' }}>

                                            <Grid style={{ display: 'flex', flexDirection: 'column' }}>
                                                {
                                                    tema?.posts.length >= 1 && <>
                                                        <Typography style={{ color: 'white' }}> <b>Último Post por: </b> {tema.posts[tema.posts?.length - 1]?.autor?.username} </Typography>
                                                        <Typography style={{ color: 'white' }}>Posteado: {formattedDate} </Typography>

                                                    </>
                                                }

                                            </Grid>


                                        </Grid>

                                        {
                                            user.rol === 'admin' &&
                                            <Grid style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
                                                <MdDeleteForever onClick={() => handleDeleteTemas(tema._id)} style={{ color: 'white', fontSize: '20px' }} />
                                                <FaEdit style={{ color: 'white', fontSize: '20px' }} />
                                            </Grid>
                                        }

                                    </Link>
                                )
                            }


                            )
                            :
                            <Typography style={{ color: 'white', fontStyle: 'italic', fontSize: '20px' }}>No existen temas creados</Typography>
                        }

                    </Grid>
                }
            </Grid>

        </Layout>
    )
}

export default Foro