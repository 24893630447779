import React, { useState, useEffect } from 'react';

// Helper function to format date with Argentina time zone
const getArgentinaTime = (date) => {
  return new Date(date.toLocaleString('en-US', { timeZone: 'America/Argentina/Buenos_Aires' }));
};

// CountdownTimer component to handle individual event countdown
const CountdownTimer = ({ targetDate, eventName, onComplete }) => {
  const calculateTimeLeft = () => {
    const difference = +new Date(targetDate) - +new Date();
    
    if (difference >= 0) {
      const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
      const minutes = Math.floor((difference / 1000 / 60) % 60);
      const seconds = Math.floor((difference / 1000) % 60);

      // Formatear el tiempo como "HH:mm:ss"
      return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    } else {
      return '00:00:00';
    }
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      const newTimeLeft = calculateTimeLeft();
      setTimeLeft(newTimeLeft);

      // When countdown reaches zero, call onComplete to trigger the next cycle
      if (newTimeLeft === '00:00:00') {
        clearInterval(timer); // Clear the interval to stop updating after the countdown reaches zero
        onComplete();
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [timeLeft, onComplete]);

  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', margin: '10px 0', justifyContent: 'space-between' }}>
      <h3 style={{ color: 'white', marginRight: '10px', fontSize: '10px' }}>{eventName}</h3>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span style={{ color: 'gold', fontSize: '12px', fontWeight: 600 }}>{timeLeft}</span>
      </div>
    </div>
  );
};

// EventTimer component to manage the timers for each event type
const EventTimer = ({ eventName, eventTimes }) => {
  const [nextEventTime, setNextEventTime] = useState(null);

  // Function to get the next upcoming event time based on the current time
  const getNextEventTime = () => {
    const now = getArgentinaTime(new Date());
    const upcomingTimes = eventTimes.map((time) => {
      const [hours, minutes] = time.split(':');
      const eventDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), hours, minutes);

      // If the event time has passed today, set it for tomorrow
      if (eventDate < now) {
        eventDate.setDate(eventDate.getDate() + 1);
      }

      return eventDate;
    });

    // Find the closest upcoming event time
    return upcomingTimes.sort((a, b) => a - b)[0];
  };

  useEffect(() => {
    setNextEventTime(getNextEventTime());
  }, [eventTimes]);

  const handleEventComplete = () => {
    setTimeout(() => {
      setNextEventTime(getNextEventTime());
    }, 5 * 60 * 1000); // 5 minutos en milisegundos
  };

  return nextEventTime ? (
    <CountdownTimer
      key={`${eventName}-${nextEventTime}`}
      targetDate={nextEventTime}
      eventName={eventName}
      onComplete={handleEventComplete}
    />
  ) : null;
};

// MultiEventTimer component to render all events and their timers
const MultiEventTimer = () => {
  const eventSchedules = {
    "Devil Square": ['01:30', '03:30', '05:30', '07:30', '09:30', '11:30','13:30','15:30','17:30','19:30','21:30','23:30'],
    "Loteria": ['01:00', '05:00', '09:00', '13:00', '17:00', '20:50'],
    "Blood Castle": ['03:00', '07:00', '11:00', '15:00', '19:00', '22:50'],
    "White Wizard Invasion": ['00:50', '04:50', '08:50', '12:50','16:50','20:50'],
    "Red Dragon Invasion": ['00:15', '04:15', '08:15','12:15','16:15','20:15'],
    "Invasiones doradas": ['00:25', '04:25', '08:25','12:25','16:25','20:25'],
    "Skeleton King": ['00:05', '04:05', '08:05','12:05','16:05','20:05'],
    "Invasion Icarus": ['20:00'],
  };

  return (
    <div>
      {Object.keys(eventSchedules).map((eventName) => (
        <EventTimer key={eventName} eventName={eventName} eventTimes={eventSchedules[eventName]} />
      ))}
    </div>
  );
};

export default MultiEventTimer;
