import React, { useEffect } from 'react';
import { getImageForClass, drawFlag, convertGMarkToHex, convertMinutes } from '../../utils';
import './tabla.css';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
function TablaOnline({ jugadores }) {
  useEffect(() => {
    const renderFlags = () => {
      jugadores.forEach((jugador) => {
        if (jugador.G_Name !== '--') {
          const canvasId = `flag-online-${jugador.Orden}`;
          const canvas = document.getElementById(canvasId);
          const context = canvas.getContext('2d');

          // Limpiar el canvas
          context.clearRect(0, 0, canvas.width, canvas.height);

          // Dibujar los nuevos flags
          drawFlag(canvasId, convertGMarkToHex(jugador.G_Mark));
        }
      });
    };

    renderFlags();

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        renderFlags();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [jugadores]);

  const maxOrden = Math.max(...jugadores.map(jugador => jugador.Orden), 0);
  const nuevoOrden = maxOrden + 1;
  return (
    <TableContainer component={Paper}>
      <Table className='table'>
        <TableHead>
          <TableRow style={{ color: 'white' }}>
            <TableCell style={{ color: 'white', textAlign: 'center', textTransform: 'uppercase' }}>#</TableCell>
            <TableCell style={{ color: 'white', textAlign: 'center', textTransform: 'uppercase' }}>Personaje</TableCell>
            <TableCell style={{ color: 'white', textAlign: 'center', textTransform: 'uppercase' }}>Clase</TableCell>
            <TableCell style={{ color: 'white', textAlign: 'center', textTransform: 'uppercase' }}>Clan</TableCell>
            <TableCell style={{ color: 'white', textAlign: 'center', textTransform: 'uppercase' }}>Tiempo conectado</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {jugadores.map((jugador) => (
            <TableRow key={jugador.Orden}>
              <TableCell style={{ color: '#cdcdcd' }}>{jugador.Orden}</TableCell>

              <TableCell style={{ color: '#cdcdcd', padding: '8px 16px' }}>
                <div
                  className="connected"
                  style={{
                    display: 'flex',
                    alignItems: 'center', // Centra verticalmente
                    justifyContent: 'flex-start', // Alinea al inicio horizontalmente
                  }}
                >
                  <div
                    className="player-container"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '100%', // Asegura que el contenedor ocupe todo el ancho
                    }}
                  >
                    {/* Punto verde */}
                    <span id="pulsatingDot" style={{ marginRight: '5px' }} />

                    {/* Nombre del jugador y posible icono de Guild Master */}
                    <Grid
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        flex: 1, /* Permite que este contenedor ocupe el resto del espacio disponible */
                      }}
                    >
                      {/* Nombre del jugador */}
                      <span
                        style={{
                          color: 'green',
                          textAlign: 'left', /* Alinea el texto a la izquierda */
                          fontSize: '14px',
                          whiteSpace: 'nowrap', /* Evita el quiebre de línea */
                        }}
                      >
                        {jugador.name}
                      </span>

                      {/* Icono de Guild Master, si aplica */}
                      {jugador.IsMaster === 1 && (
                        <img
                          src="assets/img/star.gif"
                          alt="Guild Master"
                          title="Es Guild Master"
                          style={{
                            width: '12px',
                            height: '12px',
                            border: 'none',
                            marginLeft: '4px',
                          }}
                        />
                      )}
                    </Grid>
                  </div>
                </div>
              </TableCell>



              <TableCell style={{ color: '#cdcdcd' }}><img src={`assets/img/${getImageForClass(jugador.Class)}`} width="30" alt="Conectado" title={jugador.Class} style={{ marginRight: '8px' }} />
              </TableCell>
              <TableCell style={{ color: '#cdcdcd' }}>
                {jugador.G_Name !== '--' && (
                  <div className="clan-container">
                    <canvas className="canvas-clan" id={`flag-online-${jugador.Orden}`} width="24" height="24" data-flag={convertGMarkToHex(jugador.G_Mark)} style={{ marginRight: '8px' }}></canvas>
                    <span style={{ color: '#cdcdcd' }}>{jugador.G_Name}</span>
                  </div>
                )}
              </TableCell>
              <TableCell style={{ color: '#cdcdcd' }}>{convertMinutes(jugador.TimeConnectedMinutes)}</TableCell>

            </TableRow>
          ))}
          <TableRow>
            <TableCell style={{ color: '#cdcdcd' }}>{nuevoOrden}</TableCell>
            <TableCell colSpan={4} style={{ textAlign: 'center', color: '#cdcdcd' }}>¡¡PODRÍAS SER VOS!!</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default TablaOnline;
