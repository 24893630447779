import React, { useState, useEffect, useRef, useCallback, useContext } from "react";
import "./DropdownPurchase.css";
import PurchasePopup from "./PurchasePopup";
import { Box, Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Grid, Radio, RadioGroup, Step, StepLabel, Stepper, Typography } from "@mui/material";
import { MdCheck, MdEdit } from "react-icons/md";
import axios from "axios";
import { SiBinance, SiMercadopago } from "react-icons/si";
import AuthContext from "../../../AuthContext";

function useOnClickOutside(ref, handler) {
  useEffect(() => {
    const listener = (event) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }

      handler(event);
    };

    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
}

function DropdownPurchase({ closeNavbar, handleMessage }) {

  const [click, setClick] = useState(false);
  const { user } = useContext(AuthContext);


  console.log('user', user?.username)
  const handleClick = () => setClick(!click);
  
  const divRef = useRef();
  const handler = useCallback(() => setClick(true), []);
  useOnClickOutside(divRef, handler);

  const [showPopupPurchase, setShowPopupPurchase] = useState(false);
  const [amount, setAmount] = useState(100);
  const [isOpen, setIsOpen] = useState(false);
  const [editingId, setEditingId] = useState(null); // Identificador del ítem que se está editando
  const [prices, setPrices] = useState([]); // Estado para los precios traídos del backend

  useEffect(() => {
    // Simula llamada al backend para obtener precios dinámicos
    const fetchPrices = async () => {
      try {
        const response = await axios.get('https://backmu.vercel.app/coin');
        setPrices(response.data); // Asumiendo que el backend devuelve un array de precios
      } catch (error) {
        console.error('Error fetching prices:', error);
      }
    };

    fetchPrices();
  }, []);

  const handleEditClick = (id, e) => {
    setEditingId(id); // Establece el ID del ítem que se está editando
    e.stopPropagation();
  };



  const handleInputChange = (id, event) => {
    const newPrice = parseFloat(event.target.value); // Asegúrate de tratar el valor como número
    setPrices(prices.map(item =>
      item._id === id ? { ...item, precio: newPrice } : item
    )); // Actualiza el precio correspondiente
  };

  const handleInputClick = (event) => {
    event.stopPropagation(); // Detiene la propagación del clic para que no se active togglePopupPurchase
  };

  const handleConfirmClick = async (id, e) => {
    // Aquí deberías implementar la lógica para guardar los cambios en el backend
    e.stopPropagation()
    try {
      const updatedItem = prices.find(item => item._id === id);
      await axios.put(`https://backmu.vercel.app/coin/${id}`, updatedItem);
      setEditingId(null); // Finaliza la edición
    } catch (error) {
      console.error('Error updating price:', error);
    }
  };

  function togglePopupPurchase(amountt) {
    setAmount(amountt);
    setShowPopupPurchase(!showPopupPurchase);
  }
  const [open, setOpen] = useState(false);
  const [openBnb, setOpenBnb] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpenBnb = () => {
    setOpenBnb(true);
  };
  const handleCloseBnb = () => {
    setOpenBnb(false);
  };

  const [activeStep, setActiveStep] = useState(0);
  const [selectedPackage, setSelectedPackage] = useState('');
  const [activeStepBnb, setActiveStepBnb] = useState(0);
  const [selectedPackageBnb, setSelectedPackageBnb] = useState('');


  const steps = ['Seleccionar Paquete', 'Confirmación', 'Pago'];

  const handleNextBnb = () => {
    if (activeStepBnb === 0 && !selectedPackageBnb) {
      alert("Debes seleccionar un paquete.");
      return;
    }
    setActiveStepBnb((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBackBnb = () => {
    setActiveStepBnb((prevActiveStep) => prevActiveStep - 1);
  };

  const handlePackageSelectBnb = (packageName) => {
    setSelectedPackageBnb(packageName);
  };

  const handleNext = () => {
    if (activeStep === 0 && !selectedPackage) {
      alert("Debes seleccionar un paquete.");
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handlePackageSelect = (packageName) => {
    setSelectedPackage(packageName);
  };

  // Definimos los paquetes con sus nombres y descripciones
  const packages = [
    { name: '5.000 Gold Coins', description: '$10.000 PESOS', price: 10000},
    { name: '10.000 Gold Coins', description: '$15.000 PESOS', price: 15000 },
    { name: '15.000 Gold Coins', description: '23.000 PESOS', price: 23000 }
  ];
  // Definimos los paquetes con sus nombres y descripciones
  const packagesBnb = [
    { name: '5.000 Gold Coins', description: '$10 USDT', qr: 'https://res.cloudinary.com/dks7seyl8/image/upload/v1724425776/e0dv3wbp7knuu0nmguwk.jpg' },
    { name: '10.000 Gold Coins', description: '$15 USDT', qr: 'https://res.cloudinary.com/dks7seyl8/image/upload/v1724425776/fm0rhn43a0m3umynxr1v.jpg' },
    { name: '15.000 Gold Coins', description: '23 USDT', qr: 'https://res.cloudinary.com/dks7seyl8/image/upload/v1724425776/uxa2la9mhy73ajdzidng.jpg' }
  ];

  const usuario = user?.username

  const [width, setWidth] = useState(window.innerWidth);
  const isMobile = width <= 768;

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const [paymentLink, setPaymentLink] = useState("");
  const handlePayment = async () => {
    // Llamar al backend para crear la preferencia de pago
    if (!selectedPackage) return;

    try {
      const response = await axios.post('https://backmu.vercel.app/api/pago/preference', {
        title: selectedPackage.name,
        unit_price: selectedPackage.price,
        description: selectedPackage.name,
        quantity: 1,
        external_reference: usuario
      });
      
      if (response.data && response.data.paymentUrl) {
        setPaymentLink(response.data.paymentUrl);
        setActiveStep(2); // Avanzar al paso de pago
      } else {
        alert("Error al generar la preferencia de pago.");
      }
    } catch (error) {
      console.error("Error al contactar con el backend de Mercado Pago", error);
      alert("Hubo un problema al crear la preferencia de pago.");
    }
  };
  return (
    <>
      <ul
        ref={divRef}
        className={
          click ? "dropdownpurchase-menu clicked" : "dropdownpurchase-menu"
        }
      >
        <div style={{ width: "99%" }} className="dropdowncomunidad-container">
          <div
            style={{ width: isMobile ? '100%' : "95%", borderBottom: "1px solid #4b4b4b8f", textAlign: isMobile && 'center' }}
            className="dropdowns-title-container"
          >
            <div
              style={{ display: "flex", alignItems: "center" }}
              className="dropdownchatconfig-link"
              onClick={closeNavbar}
            >
              <h3
                style={{
                  width: "100%", fontWeight: "bold",
                  fontFamily: "inter",
                  color: "white",
                  fontSize: '16px'
                }}
              >
                Comprar Gold Coins  - Seleccionar método de pago
              </h3>
            </div>
          </div>
          <li style={{width: isMobile && '100%'}}>

            <div className="purchase-popup-secondary-buttons" style={{ display: 'flex', flexDirection: 'row', gap: '5px', width: '100%' }}>
              <Grid
                style={{ width: "100%", gap: '5%', display: 'flex', flexDirection: 'column', alignItems: 'center', fontSize: '16px' }}
                className="purchase-popup-secondary-button-mp"
                onClick={() => handleClickOpen()}
              >
                <SiMercadopago style={{ fontSize: '20px' }} />
                Mercado Pago
              </Grid>
              <Grid
                style={{ width: "100%", gap: '5%', backgroundColor: '#fcd434', display: 'flex', flexDirection: 'column', alignItems: 'center', fontSize: '16px' }}
                className="purchase-popup-secondary-button-mp"
                onClick={() => handleClickOpenBnb()}
              >
                <SiBinance style={{ color: 'white', fontSize: '20px' }} />
                Binance
              </Grid>
            </div>
          </li>



          <Dialog open={open} onClose={handleClose} fullWidth maxWidth={'md'}>
            <DialogTitle>Selecciona un Paquete - Mercado Pago</DialogTitle>
            <DialogContent>
              <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>

              {activeStep === 0 && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    flexDirection: isMobile && 'column',
                    marginTop: 2
                  }}
                >
                  {packages.map((pkg) => (
                    <Card
                      key={pkg.name}
                      onClick={() => handlePackageSelect(pkg)}
                      sx={{
                        border: selectedPackage?.name === pkg.name ? '2px solid #3f51b5' : '1px solid #ddd',
                        cursor: 'pointer',
                        flex: 1,
                        margin: '0 10px',
                        transition: 'border 0.3s'
                      }}
                    >
                      <CardContent>
                        <Typography variant="h6">{pkg.name}</Typography>
                        <Typography variant="body2" style={{ fontWeight: 800 }}>
                          {pkg.description}
                        </Typography>
                      </CardContent>
                    </Card>
                  ))}
                </Box>
              )}

              {activeStep === 1 && (
                <Box mt={2} style={{ textAlign: 'center', display: 'flex', flexDirection: 'column' }}>
                  <p>Has seleccionado el {selectedPackage?.name}.</p>

                  <h3 style={{ color: '#cd100a' }}>IMPORTANTE</h3>
                  <p style={{ border: '1px #cd100a solid', padding: 5, borderRadius: '5px' }}>Una vez realizado el pago el sistema va a acreditar automáticamente las gold coins en tu cuenta, si tiene algún problema con la acreditación podes mandar un correo con el comprobante de pago y el id de tu cuenta de juego a vip@muserendipia.com para poder revisar al caso.</p>
                </Box>
              )}


              {activeStep === 2 && (
                <Box mt={2} style={{ textAlign: 'center', display: 'flex', flexDirection: 'column' }}>
                  <Grid style={{ backgroundColor: '#009ee5', width: isMobile ? '20%':'15%', color: 'white', fontWeight: 600, padding: 5, margin: '0 auto', borderRadius: '5px', cursor: 'pointer' }}>
                    <a href={paymentLink} target="_blank" style={{ textDecoration: 'none', color: 'inherit' }}>Pagar </a>
                  </Grid>
                  <h3 style={{ color: '#cd100a' }}>IMPORTANTE</h3>
                  <p style={{ border: '1px #cd100a solid', padding: 5, borderRadius: '5px' }}>Una vez el pago esté realizado tienes que mandar un correo a <b> vip@muserendipia.com </b> con el comprobante de pago y el id de tu cuenta en el juego.

                    La acreditación de gold coins puede tardar de 24hs a 48hs.</p>
                </Box>
              )}
            </DialogContent>

            <DialogActions>
              {activeStep !== 0 && (
                <Button onClick={handleBack} color="inherit">
                  Atrás
                </Button>
              )}

              {activeStep === steps.length - 1 ? (
                <Button onClick={handleClose}>Cerrar</Button>
              ) : (
                <Button
                  onClick={() => {
                    if (activeStep === 1) {
                      // Llama a handlePayment para generar la preferencia de pago
                      handlePayment();
                    }
                    // Luego avanza al siguiente paso
                    handleNext();
                  }}
                >
                  {activeStep === steps.length - 2 ? 'Siguiente' : 'Siguiente'}
                </Button>
              )}
              

            </DialogActions>
          </Dialog>


          <Dialog open={openBnb} onClose={handleCloseBnb} fullWidth maxWidth={'md'}>
            <DialogTitle>Selecciona un Paquete - Binance </DialogTitle>
            <DialogContent>
              <Stepper activeStep={activeStepBnb} alternativeLabel>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>

              {activeStepBnb === 0 && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    marginTop: 2
                  }}
                >
                  {packagesBnb.map((pkg) => (
                    <Card
                      key={pkg.name}
                      onClick={() => handlePackageSelectBnb(pkg)}
                      sx={{
                        border: selectedPackageBnb?.name === pkg.name ? '2px solid #3f51b5' : '1px solid #ddd',
                        cursor: 'pointer',
                        flex: 1,
                        margin: '0 10px',
                        transition: 'border 0.3s'
                      }}
                    >
                      <CardContent>
                        <Typography variant="h6">{pkg.name}</Typography>
                        <Typography variant="body2" style={{ fontWeight: 800 }}>
                          {pkg.description}
                        </Typography>
                      </CardContent>
                    </Card>
                  ))}
                </Box>
              )}

              {activeStepBnb === 1 && (
                <Box mt={2} style={{ textAlign: 'center', display: 'flex', flexDirection: 'column' }}>
                  <p>Has seleccionado el {selectedPackageBnb?.name}.</p>
                  <h3 style={{ color: '#cd100a' }}>IMPORTANTE</h3>
                  <p style={{ border: '1px #cd100a solid', padding: 5, borderRadius: '5px' }}>Una vez el pago esté realizado tienes que mandar un correo a <b> vip@muserendipia.com </b> con el comprobante de pago y el id de tu cuenta en el juego.

                    La acreditación de gold coins puede tardar de 24hs a 48hs.</p>
                </Box>
              )}
              {activeStepBnb === 2 && (
                <Box mt={2} style={{ textAlign: 'center', display: 'flex', flexDirection: 'column' }}>
                  <Grid style={{ margin: '0 auto' }}>
                    <img src={selectedPackageBnb?.qr} style={{ width: '25%' }} />
                  </Grid>
                  <h3 style={{ color: '#cd100a' }}>IMPORTANTE</h3>
                  <p style={{ border: '1px #cd100a solid', padding: 5, borderRadius: '5px' }}>Una vez el pago esté realizado tienes que mandar un correo a <b> vip@muserendipia.com </b> con el comprobante de pago y el id de tu cuenta en el juego.

                    La acreditación de gold coins puede tardar de 24hs a 48hs.</p>
                </Box>
              )}
            </DialogContent>

            <DialogActions>
              {activeStepBnb !== 0 && (
                <Button onClick={handleBackBnb} color="inherit">
                  Atrás
                </Button>
              )}

              {activeStepBnb === steps.length - 1 ? (
                <Button onClick={handleCloseBnb}>Cerrar</Button>
              ) : (
                <Button onClick={handleNextBnb}>
                  {activeStepBnb === steps.length - 2 ? 'Siguiente' : 'Siguiente'}
                </Button>
              )}
            </DialogActions>
          </Dialog>



          {showPopupPurchase === true && (
            <PurchasePopup
              amountt={amount}
              closePopup={() => togglePopupPurchase()}
              setIsOpen={() => setIsOpen()}
            />
          )}
        </div>
      </ul>
    </>
  );
}

export default DropdownPurchase;
